import {Box} from "@mui/material";
import Header from "../../components/Header";
import React, {useEffect} from "react";
import {getToken} from "../Token";
import {useNavigate} from "react-router-dom";


const Dashboard = () => {
    const navigate = useNavigate()

    useEffect(() => {
        fetchUserData();
    }, []);
    const fetchUserData = () => {
        const token = getToken()
        fetch('http://167.235.133.126:8000/user/', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
            },
        })
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    navigate('/login')
                }
            })
    };

    return (
        <Box m="20px">
            {/* HEADER */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="DASHBOARD" subtitle="Welcome to your dashboard"/>

            </Box>
        </Box>
    );
};

export default Dashboard;
