import React, {useState} from "react";
import {Routes, Route} from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Users from "./scenes/users";
import {CssBaseline, ThemeProvider} from "@mui/material";
import {ColorModeContext, useMode} from "./theme";
import Login from "./scenes/login/login";
import Vouchers from "./scenes/vouchers";
import VoucherAddForm from "./scenes/vouchers/add";
import VoucherUpdateForm from "./scenes/vouchers/update";
import VoucherApply from "./scenes/voucher_apply/voucher_apply";

function App() {
    const [theme, colorMode] = useMode();
    const [isSidebar, setIsSidebar] = useState(true);

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <div className="app">
                    <Sidebar isSidebar={isSidebar}/>
                    <main className="content">
                        <Topbar setIsSidebar={setIsSidebar}/>
                        <Routes>
                            <Route path="/" element={
                                    <Dashboard/>}
                            />
                            <Route path="/users" element={
                                    <Users/>
                            }/>
                            <Route path="/vouchers" element={
                                    <Vouchers/>
                            }/>
                            <Route path="/voucher/add" element={
                                    <VoucherAddForm/>
                            }/>
                            <Route path="/voucher/update" element={
                                    <VoucherUpdateForm/>
                            }/>
                            <Route path="/voucher/apply" element={
                                    <VoucherApply/>
                            }/>
                            <Route path="/login" element={<Login/>}/>
                        </Routes>
                    </main>
                </div>
            </ThemeProvider>

        </ColorModeContext.Provider>

    )
        ;
}

export default App;
