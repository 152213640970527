import {Box, Typography, useTheme} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {tokens} from "../../theme";
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import DisabledByDefaultRoundedIcon from '@mui/icons-material/DisabledByDefaultRounded';
import Header from "../../components/Header";
import React, {useEffect, useState} from "react";
import {getToken} from "../Token";
import {useNavigate} from "react-router-dom";

const Users = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const columns = [
        {field: "id", headerName: "ID"},
        {
            field: "email",
            headerName: "Email",
            flex: 1,
            cellClassName: "name-column--cell",
        },
        {
            field: "isActive",
            headerName: "Has Access",
            flex: 1,
            renderCell: ({row: {is_active}}) => {
                return (
                    <Box
                        width="60%"
                        m="0 auto"
                        p="5px"
                        display="flex"
                        justifyContent="center"
                        backgroundColor={
                            is_active === true
                                ? colors.greenAccent[600]
                                : is_active === false
                                    ? colors.redAccent[600]
                                    : colors.redAccent[600]
                        }
                        borderRadius="4px"
                    >
                        {is_active === true && <CheckBoxRoundedIcon/>}
                        {is_active === false && <DisabledByDefaultRoundedIcon/>}
                        <Typography color={colors.grey[100]} sx={{ml: "5px"}}>
                            {is_active}
                        </Typography>
                    </Box>
                );
            },
        },
    ];
    const [userData, setUserData] = useState([]);
    const navigate = useNavigate()

    useEffect(() => {
        fetchUsersData();
    }, []);
    const fetchUsersData = () => {
        const token = getToken()
        fetch('http://167.235.133.126:8000/users/', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
            },
        })
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    navigate('/login')
                }
            })
            .then(data => {
                setUserData(data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };
    return (
        <Box m="20px">
            <Header title="Users" subtitle="Managing the users"/>
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                }}
            >
                <DataGrid checkboxSelection rows={userData} columns={columns}/>
            </Box>
        </Box>
    );
};

export default Users;
