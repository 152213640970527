import {Box, Button, Typography, useTheme} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {tokens} from "../../theme";
import Header from "../../components/Header";
import React, {useEffect, useState} from "react";
import {getToken} from "../Token";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import DisabledByDefaultRoundedIcon from "@mui/icons-material/DisabledByDefaultRounded";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import {useNavigate} from "react-router-dom";

const Vouchers = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const columns = [
        {field: "id", headerName: "ID"},
        {
            field: "email",
            headerName: "Email",
            flex: 1,
        },
        {
            field: "type",
            headerName: "Type",
            flex: 1,
            renderCell: params => {
                if (params.row.type === 1) {
                    return <Typography>{params.row.service}</Typography>
                } else {
                    return <Typography>{params.row.amount}€</Typography>
                }
            }
        },
        {
            field: "created_at",
            headerName: "Created At",
            flex: 1,
        },
        {
            field: "applied_at",
            headerName: "Applied At",
            flex: 1,
        },
        {
            field: "isValid",
            headerName: "Is Valid",
            flex: 1,
            renderCell: ({row: {is_valid}}) => {
                return (
                    <Box
                        width="60%"
                        m="0 auto"
                        p="5px"
                        display="flex"
                        justifyContent="center"
                        backgroundColor={
                            is_valid === true
                                ? colors.greenAccent[600]
                                : is_valid === false
                                    ? colors.redAccent[600]
                                    : colors.redAccent[600]
                        }
                        borderRadius="4px"
                    >
                        {is_valid === true && <CheckBoxRoundedIcon/>}
                        {is_valid === false && <DisabledByDefaultRoundedIcon/>}
                        <Typography color={colors.grey[100]} sx={{ml: "5px"}}>
                            {is_valid}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "send_email",
            headerName: "Send Email",
            flex: 1,
            renderCell: ({row: {id}}) => {
                return (
                    <Box
                        width="60%"
                        m="0 auto"
                        p="5px"
                        display="flex"
                        justifyContent="center"
                        backgroundColor={
                            colors.blueAccent[600]
                        }
                        borderRadius="4px"
                    >
                        <Button onClick={() => {
                            const token = getToken()
                            fetch('http://167.235.133.126:8000/voucher/' + id + '/send/', {
                                method: 'GET',
                                headers: {
                                    'Authorization': 'Bearer ' + token,
                                },
                            }).then(response => {

                                if (response.status !== 200) {
                                    alert("Email failed")
                                }
                            })
                        }}>
                            Send Voucher
                        </Button>
                    </Box>
                );
            },
        },
        {
            field: "edit_voucher",
            headerName: "Edit",
            flex: 1,
            renderCell: ({row}) => {
                return (
                    <Box
                        width="60%"
                        m="0 auto"
                        p="5px"
                        display="flex"
                        justifyContent="center"
                        backgroundColor={
                            colors.blueAccent[400]
                        }
                        borderRadius="4px"
                    >
                        <Button onClick={() => {
                            navigate('/voucher/update', {
                                state: {
                                    id: row.id,
                                    email: row.email,
                                    service: row.service,
                                    amount: row.amount,
                                    type: row.type,
                                }
                            })
                        }}>
                            Edit
                        </Button>
                    </Box>
                );
            },
        },
    ];
    const [voucherData, setVoucherData] = useState([]);
    const navigate = useNavigate()

    const handleButtonClick = () => {
        navigate("/voucher/add");
    };

    useEffect(() => {
        fetchVoucherData();
    }, []);
    const fetchVoucherData = () => {
        const token = getToken()
        fetch('http://167.235.133.126:8000/vouchers/', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
            },
        })
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    navigate('/login')
                }
            })
            .then(data => {
                setVoucherData(data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };
    return (
        <Box m="20px">
            <Header title="Vouchers" subtitle="List of Vouchers"/>
            <Box sx={{display: "flex", justifyContent: "flex-end"}}>
                <Button
                    onClick={handleButtonClick}
                    sx={{
                        backgroundColor: colors.blueAccent[700],
                        color: colors.grey[100],
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px 20px",
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <AddCircleRoundedIcon sx={{mr: "10px"}}/>
                    Add Voucher
                </Button>
            </Box>
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                }}
            >
                <DataGrid checkboxSelection rows={voucherData} columns={columns}/>
            </Box>
        </Box>
    );
};

export default Vouchers;
