import {Box, Button, TextField} from "@mui/material";
import {Formik} from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import {useState} from "react";
import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import {getToken} from "../Token";
import {useNavigate} from "react-router-dom";


export default function VoucherAddForm() {
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const navigate = useNavigate()

    const handleFormSubmit = (values) => {
        values.vouchertype = voucherType
        if (values.vouchertype === 1) {
            values.voucherCredit = 0
        } else {
            values.service = ""
        }
        values.type = values.vouchertype;
        values.amount = values.voucherCredit;
        delete values.vouchertype;
        delete values.voucherCredit;

        const token = getToken()
        fetch('http://167.235.133.126:8000/voucher/', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(values)
        })
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    navigate('/login')
                }
            })
            .then(data => {
                navigate('/vouchers')
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };
    const [voucherType, setVoucherType] = useState(1);

    const handleChangeSelect = (event) => {
        setVoucherType(event.target.value);
    };


    return (
        <Box m="20px">
            <Header title="CREATE Voucher" subtitle="Create a New Voucher"/>
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": {gridColumn: isNonMobile ? undefined : "span 4"},
                            }}
                        >
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.email}
                                name="email"
                                error={!!touched.email && !!errors.email}
                                helperText={touched.email && errors.email}
                                sx={{gridColumn: "span 4"}}
                            />
                            <InputLabel id="demo-simple-select-label">Voucher Type</InputLabel>
                            <Select
                                variant="filled"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={voucherType}
                                label="Voucher Type"
                                name="voucherType"
                                onChange={(event) => {
                                    handleChangeSelect(event); // Handle select change separately
                                }}
                            >
                                <MenuItem value={1}>Service</MenuItem>
                                <MenuItem value={2}>Money</MenuItem>
                            </Select>
                            {voucherType === 1 ? (
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Service"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.contact}
                                    name="service"
                                    error={!!touched.contact && !!errors.contact}
                                    helperText={touched.contact && errors.contact}
                                    sx={{
                                        gridColumn: "span 4",
                                    }}
                                />
                            ) : (
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="number"
                                    label="Voucher Credit"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.integerField}
                                    name="voucherCredit"
                                    error={!!touched.integerField && !!errors.integerField}
                                    helperText={touched.integerField && errors.integerField}
                                    sx={{
                                        gridColumn: "span 4",
                                    }}
                                />
                            )}
                        </Box>
                        <Box display="flex" justifyContent="center" mt="20px">
                            <Button type="submit" color="secondary" variant="contained" sx={{
                                fontSize: "14px",
                                fontWeight: "bold",
                                padding: "10px 20px",
                                display: "flex",
                                alignItems: "center",
                            }}>
                                Create New Voucher
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    );
};
//
const phoneRegExp =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
    email: yup.string().email("invalid email").required("required"),
});
const initialValues = {
    email: "",
    vouchertype: 1,
    service: "",
    voucherCredit: 0,
};