import React, {useEffect, useState} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {getToken} from "../Token"


import "./voucher_apply.css";
import {Box, Typography} from "@mui/material";
import BlockRounded from "@mui/icons-material/BlockRounded";
import HourglassFullRoundedIcon from '@mui/icons-material/HourglassFullRounded';
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";


export default function VoucherApply() {
    const [searchParams, setSearchParams] = useSearchParams();
    const voucherEncryptedID = searchParams.get("encrypted_id")
    const [isLoading, setIsLoading] = useState(true);
    let statusVoucher;
    const navigate = useNavigate();
    const [resultText, setResultText] = useState('');
    const [resultIcon, setResultIcon] = useState(null);


    useEffect(() => {
        const setVoucherResult = () => {
            if (statusVoucher === 1) {
                setResultText('Voucher Applied!');
                setResultIcon(
                    <CheckBoxRoundedIcon
                        sx={{
                            color: 'green',
                            fontSize: 'inherit',
                        }}
                    />
                )

            } else if (statusVoucher === 2) {
                setResultText('Voucher Expired!');
                setResultIcon(
                    <BlockRounded
                        sx={{
                            color: 'red',
                            fontSize: 'inherit',
                        }}
                    />
                )

            } else if (statusVoucher === 3) {
                setResultText('Voucher already USED!')
                setResultIcon(
                    <BlockRounded
                        sx={{
                            color: 'red',
                            fontSize: 'inherit',
                        }}
                    />
                )
            } else {
                setResultText('Voucher Invalid!')
                setResultIcon(
                    <BlockRounded
                        sx={{
                            color: 'red',
                            fontSize: 'inherit',
                        }}
                    />
                )
            }
            setIsLoading(false)

        }
        const fetchVoucherData = () => {
            const token = getToken()
            fetch('http://167.235.133.126:8000/voucher/apply/' + voucherEncryptedID, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token,
                },
            })
                .then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        navigate('/login')
                    }
                }).then((response) => {
                statusVoucher = response.status;
                setVoucherResult();
            })
        };

        fetchVoucherData();
    }, []);


    return (
        <Box>
            {isLoading ? (
                <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignSelf: "center",
                    alignItems: "center",
                    height: '100vh',
                    width: '100vw',
                    margin: '0',
                }}>
                    <Typography variant="h2">
                        LOADING
                    </Typography>
                    <Typography variant="h1">
                        <HourglassFullRoundedIcon
                            sx={{
                                color: 'white',
                                fontSize: 'inherit',
                            }}
                        />
                    </Typography>
                </Box>
            ) : (
                <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignSelf: "center",
                    alignItems: "center",
                    height: '100vh',
                    width: '100vw',
                    margin: '0',
                }}>
                    <Typography variant="h2">
                        {resultText}
                    </Typography>
                    <Typography variant="h1">
                        {resultIcon}
                    </Typography>
                </Box>
            )}
        </Box>
    )
}